/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
.Roboto-font {
  font-family: "Roboto" !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import "~ngx-toastr/toastr";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "assets/css/mixins";
@import "assets/css/variable";
.fw-bolder {
  font-family: "Montserrat" !important;
}
body {
  background-color: $body-bg;
  font-family: "Montserrat" !important;
  .header-wrapper {
    background: $blueGradientBg;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top: 165px;
    .head-bg-g {
      background: $head-bg-g;
      box-shadow: 0 33px 6px -35px rgba(0, 0, 0, 0.16);
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .fixed-top {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background: linear-gradient(#063e9a, #0d51c4);
    }
  }
  @media (min-width: $breakpoint-laoptop) {
    .header-wrapper {
      padding-top: 150px;
    }
  }
  .rounded-10 {
    border-radius: 10px !important;
  }
  .d-contents {
    display: contents;
  }
  .custom-shadow {
    box-shadow: 0 3px 6px rgba(44, 40, 40, 0.05);
  }
  header {
    .login-signup {
      font-size: 16px;
      font-weight: 500;
    }
    @media (min-width: $breakpoint-tablet) {
      .login-signup {
        font-size: 16px;
      }
    }
    .first-top-header {
      .help-icon,
      .bell-icon {
        width: 20px;
      }
      @media (min-width: $breakpoint-tablet) {
        .help-icon,
        .bell-icon {
          width: 20px;
        }
      }
    }
    .header-logo {
      img {
        width: 45px;
      }
      @media (min-width: $breakpoint-tablet) {
        img {
          width: 65px;
        }
      }
    }
    /* ANIMATED X */
    .navbar-toggler.x {
      padding: 0.3rem 0.55rem;
      border: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &[aria-expanded="true"] {
        .icon-bar {
          &:nth-of-type(1) {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transform-origin: 10% 10%;
            -ms-transform-origin: 10% 10%;
            transform-origin: 10% 10%;
          }
          &:nth-of-type(2) {
            opacity: 0;
            filter: alpha(opacity=0);
          }
          &:nth-of-type(3) {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            -webkit-transform-origin: 10% 90%;
            -ms-transform-origin: 10% 90%;
            transform-origin: 10% 90%;
          }
        }
      }
      .icon-bar {
        width: 25px;
        display: block;
        height: 1.5px;
        background-color: #fff;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        & + .icon-bar {
          margin-top: 6px;
        }
        &:nth-of-type(1) {
          -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
        }
        &:nth-of-type(2) {
          opacity: 1;
          filter: alpha(opacity=100);
        }
        &:nth-of-type(3) {
          -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
          width: 22px;
        }
      }
    }
  }
  .menu-nav {
    .navbar-nav {
      .nav-link {
        @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-xl) {
          & {
            font-size: 13px;
          }
        }
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        margin-left: 1.5px;
        margin-right: 1.5px;
        &:hover {
          color: $white;
          transform: scale(1.05);
        }
        .active {
          color: $white;
        }
      }
    }
  }
  .live-autoplay-slider {
    height: 58px;
    margin-bottom: 5px;
    overflow: hidden;
    .auto-slide-head-card {
      background-color: $white;
      padding: 6px 8px;
      border-radius: 4px;
      background-image: url("/assets/img/top-shape.svg");
      background-repeat: no-repeat;
      background-size: auto;
      background-position: right top;
      .row.content {
        width: 180px;
        .star-inserted-text {
          border-right: 2px solid #a6bede;
        }
        .auto-slider-text {
          font-size: 14px;
          color: $dark-blue;
        }
        .auto-slider-heading {
          font-size: 16px;
          font-weight: bold;
          color: $blue;
        }
      }
    }
  }
  .batt-category-nav {
    .batt-category-box {
      &::-webkit-scrollbar {
        height: 0;
      }
      .navbar-nav {
        .nav-link {
          color: $white;
          padding: 5px 15px;
          margin: 0 2px;
          border-radius: 50rem;
          font-size: 14px;
          font-weight: 500;
          &.active,
          &:hover {
            border-radius: 50rem;
            color: $blue;
            background-color: $white;
          }
        }
      }
    }
  }
}
.btn-theme {
  color: $white;
  background: $btn-gradient;
  border-color: $btn-bordar-gradient;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 50em;
  &:hover {
    background: $btn-gradient-revers;
    color: $white;
  }
}
.btn-black {
  color: $white;
  background: $black;
  border-color: $black;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 50em;
  font-size: 14px;
  &:hover {
    opacity: 0.6;
    color: $white;
  }
}
.btn-fb-blue {
  color: $white;
  background: $fb-blue;
  border-color: $fb-blue;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 50em;
  font-size: 14px;
  &:hover {
    opacity: 0.8;
    color: $white;
  }
}
.btn-google-red {
  color: $white;
  background: $google-red;
  border-color: $google-red;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 50em;
  font-size: 14px;
  &:hover {
    opacity: 0.8;
    color: $white;
  }
}
.btn-twitter-blue {
  color: $white;
  background: $twitter-blue;
  border-color: $twitter-blue;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 50em;
  font-size: 14px;
  &:hover {
    opacity: 0.8;
    color: $white;
  }
}
.btn-light {
  color: $white;
  background: $light-blue;
  border-color: $light-blue;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 50em;
}
.form-control {
  font-size: 14px;
  border-radius: 12px;
  border-color: $light-grey;
  background-color: $extra-light-blue;
  &::placeholder {
    color: $light-blue;
    font-size: 12px;
  }
}
select.form-control {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}
.text-blue-light {
  color: $light-blue;
}
.text-blue {
  color: $blue;
}
.text-f10 {
  font-size: 0.7rem;
}
.text-f12 {
  font-size: 12px;
}
.text-f14 {
  font-size: 14px;
}
.text-f16 {
  font-size: 16px;
}
.text-f18 {
  font-size: 18px;
}
.bg-blue-gradient {
  background: $blueGradientBg;
}
.main-footer {
  background-color: $mate-blue-dark;
}
.social-media-footer-links {
  .row {
    div {
      &:first-child {
        border-radius: 60px 0 0;
      }
      &:last-child {
        border-radius: 0 60px 0 0;
      }
    }
  }
}
.auth-logo-bg {
  background-color: $white;
  padding: 6px 8px;
  border-radius: 4px;
  background-image: url("/assets/img/auth-logo-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left top;
}
.c-pointer {
  cursor: pointer;
}

.btn-gradient {
  text-decoration: none;
  padding: 5px 16px;
  border: 1px solid;
  border-radius: 50em;
  font-size: 14px;
  cursor: pointer;
  color: $white;
  background: $btn-gradient;
  border-color: $btn-bordar-gradient;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.errors-label {
  font-size: 12px;
}
.wh-30px {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.wh-40px {
  width: 40px;
  height: 40px;
}
.wh-50px {
  width: 50px;
  height: 50px;
}
.w-70px {
  width: 75px !important;
}
.w-80px {
  width: 80px !important;
}
.pt-13 {
  padding-top: 13px !important;
}
.card-shadow-sm {
  box-shadow: 0.3pt 1pt rgb(0 0 0 / 16%) !important;
}
// switch css start
.switch {
  display: inline-block;
  height: 25px;
  position: relative;
  width: 45px;
}
.switch .switch-checkbox {
  display: none;
}
.switch-slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.switch-slider:before {
  background-color: #fff;
  bottom: 6px;
  content: "";
  height: 12px;
  width: 8px;
  left: 8px;
  position: absolute;
  transition: 0.4s;
}
input:checked + .switch-slider {
  background-color: $blue;
}
input:checked + .switch-slider:before {
  transform: translateX(21px);
}
.switch-slider.round {
  border-radius: 50em;
}
.switch-slider.round:before {
  border-radius: 50em;
}
// switch css end
.filter-tabs-area {
  @media screen and (max-width: $breakpoint-laoptop) {
    & {
      text-align: center;
    }
  }
  li {
    margin-bottom: 8px;
    label,
    a,
    button {
      padding: 5px 16px;
      background-color: $white;
      border: 1px solid $dark-grey;
      color: $dark-grey;
      border-radius: 50em;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      &:active {
        transform: scale(0.92);
        opacity: 0.8;
      }
    }
    [type="radio"] {
      &:checked + label,
      & + label:hover {
        color: $white;
        background: $btn-gradient;
        border-color: $btn-bordar-gradient;
      }
    }
    a,
    button {
      &.active,
      &:hover {
        color: $white !important;
        background: $btn-gradient;
        border-color: $btn-bordar-gradient;
      }
    }
  }
}
a {
  text-decoration: none !important;
}
.text-green {
  color: $dark-green;
}
.wh-30 {
  width: 30px !important;
  height: 30px !important;
}
.wh-40 {
  width: 40px !important;
  height: 40px !important;
}
.wh-50 {
  width: 50px !important;
  height: 50px !important;
}
.wh-60 {
  width: 60px !important;
  height: 60px !important;
}
.z-index-1 {
  z-index: 1;
}
.modal-botton-design {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 280px;
}
// table css start
@media screen and (min-width: 991px) {
  .w-lg-300 {
    width: 300px !important;
  }
}
@media screen and (max-width: 580px) {
  .kingpin-table {
    font-size: 16px;
    line-height: 22px;
  }
}
.kingpin-table {
  border-radius: 8px;
  overflow: hidden;
  font-size: 0.9rem;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
}
@media screen and (max-width: 580px) {
  .kingpin-table {
    display: block;
  }
}

.table-row {
  display: table-row;
  background: #f6f6f6;
}
.table-row:nth-of-type(odd) {
  background: #e9e9e9;
}
.table-row.header {
  font-weight: 900;
  color: #ffffff;
  background: $blue;
}
.table-row.blue {
  background: $blueGradientBg0;
}
@media screen and (max-width: 580px) {
  .table-row {
    padding: 14px 0 7px;
    display: block;
  }
  .table-row.header {
    padding: 0;
    height: 6px;
  }
  .table-row.header .cell {
    display: none;
  }
  .table-row .cell {
    margin-bottom: 10px;
  }
  .table-row .cell:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }
}

.cell {
  padding: 6px 12px;
  display: table-cell;
}
@media screen and (max-width: 580px) {
  .cell {
    padding: 2px 16px;
    display: block;
  }
}
// table css close
.subscription-table {
  box-shadow: none;
  .table-row {
    background: rgba(244, 246, 249, 0.42) !important;
    .cell {
      border-bottom: 1px solid #e5e4f2 !important;
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 0;
      &:last-child:before {
        color: white;
      }
    }
    &:nth-of-type(odd) {
      background: rgba(244, 246, 249, 0.42) !important;
    }
  }
}
.bg-light-blue {
  background: $body-bg;
}
.bg-green {
  background: $green;
}

.form-control-lg {
  font-size: 100%;
  &::placeholder {
    font-size: 100%;
  }
}
.bottom-shape-design {
  background-image: url("/assets/img/bottom-shape-design.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.btn-sm {
  font-size: 10px;
  padding: 3px 10px;
}
@media (min-width: $breakpoint-laoptop) {
  .w-md-345 {
    max-width: 345px;
    min-width: 300px;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1370px;
  }
}
@media (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1470px;
  }
}
.btn-subscription {
  color: $white;
  background-color: $green;
  border-color: $green;
  &:hover {
    background-color: $btn-green;
    border-color: $btn-green;
    color: $white !important;
  }
}
.btn-subscription-blue {
  color: $white;
  background-color: $blue;
  border-color: $blue;
  &:hover {
    background-color: $blueGradientBg;
    border-color: $blueGradientBg0;
    color: $white !important;
  }
}
.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 99px;
  margin: 0 auto;
  line-height: 25px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.follow-style {
  color: $blue;
  &:active,
  &:hover {
    opacity: 0.8;
  }
}
.unfollow-style {
  color: $google-red;
  &:active,
  &:hover {
    color: $google-red !important;
    opacity: 0.8;
  }
}
.sidelist-user-name.fw-bold {
  font-size: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 3px;
}
h1 {
  font-size: 24px;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
.mat-datepicker-toggle-default-icon {
  color: #fff;
}
.owl-dots {
  display: none !important;
}
.max-100 {
  max-width: 100%;
  width: auto !important;
}
.text-muted {
  color: #444444!important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
