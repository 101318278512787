$blueGradientBg0: linear-gradient(0deg, #063e9a, #1260e6);
$blueGradientBg: linear-gradient(#063e9a, #1260e6);
$head-bg-g: linear-gradient(
  45deg,
  rgba(18, 96, 230, 0),
  rgba(17, 95, 228, 0.3),
  rgba(17, 95, 228, 0.7),
  rgba(17, 95, 228, 0.3),
  rgba(18, 96, 230, 0)
);
$white: white;
$blue: #0259cb;
$dark-blue: #205072;
$body-bg: #f1f3f9;
$dark-grey: #707273;
$dark: #55565f;
$grey: #a8abb7;
$black: #020202;
$light-grey: #eaeaea;
$light-blue: #acb5d1;
$extra-light-blue: #fcfcfc;
$mate-blue-dark: #354e57;
$btn-bordar-gradient: #32d0b0;
$btn-gradient: linear-gradient(0deg, #0180fc, #32d0b0);
$btn-gradient-revers: linear-gradient(90deg, #0180fc, #32d0b0);
$fb-blue: #1976d2;
$google-red: #f14336;
$twitter-blue: #03a9f4;
$green: #2ebd59;
$btn-green: #20b34c;
$dark-green: #51a32a;

/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 768px;
$breakpoint-laoptop: 1024px;
$breakpoint-xl: 1200px;
